import React, { useEffect, useState } from 'react';

import { Button } from './Button.jsx';

export const CookiePopup = () => {
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('acceptedCookies');

    if (!hasAcceptedCookies) {
      setShowCookiePopup(true);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShowCookiePopup(false);
  };

  return (
    showCookiePopup && (
      <div className="sticky bottom-[50px] border-2 border-[#19282f] max-w-100 text-center px-8 pb-10 pt-10 lg:rounded-[16px] lg:left-[150px] lg:w-[80%] bg-textBlack shadow-md z-[9999]">
          <div className="lg:flex lg:justify-around">
              <div className="lg:text-start">
                  <h1 className="text-[36px] font-bold text-center leading-[54px] lg:text-start">
                      We Use Cookies
                  </h1>
                  <p className="mt-[20px] lg:mt-[5px] text-[18px]">
                      We use our own and third-party cookies to personalize content and to analyze web traffic.
                      <br/>
                      <a href="/cookie-policy" className="underline cursor-pointer">
                          Read more about cookies
                      </a>
                  </p>

              </div>

              <div className="flex items-center justify-center gap-[25px] mt-8 md:gap-[55px] lg:gap-8 text-xl font-bold">
                  <div className="max-w-[180px] w-full">
                      <Button buttonClass="mini" title="Accept" onClick={handleClick}/>
                  </div>
                  <div className="max-w-[180px] w-full">
                      <Button
                          buttonClass="mini"
                          title="Reject"
                          onClick={() => setShowCookiePopup(false)}
                      />
                  </div>
              </div>
          </div>
      </div>
    )
  );
};

import React, { useContext, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { useLocation } from 'react-router-dom';
import {useNavigate} from "react-router";
import { RootContext } from '../context/RootContext.jsx';

import { Button } from './Button.jsx';
import { Checkbox } from './Checkbox.jsx';

import { registerUser } from '../api/api.js';
import {gamesList} from "../data/gamesList";
import 'react-intl-tel-input/dist/main.css';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const nameRegex = /^[a-zA-Z][a-zA-Z'\-]*[a-zA-Z] [a-zA-Z][a-zA-Z'\-]*[a-zA-Z]$/; //eslint-disable-line

export const SignupPopup = () => {
  const {
    isSignUpPopupOpen,
    closeSignUp,
    countryData,
    registrationCookie,
    setLoggedIn,
    loggedIn,
    openLogin,
    setIsThankYouPopupOpen,
  } = useContext(RootContext);
  const [errorPhone, setErrorPhone] = useState(false);

  const [formDataState, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    password: '',
    agreeToTerms: false,
    promo: false,
    countryCode: '',
    country: '',

  });
  const [errors, setErrors] = useState({
    fullName: '',
    phone: '',
    email: '',
    password: '',
    agreeToTerms: '',
    countryCode: '',
    country: '',
  });

  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleLogin = () => {
    closeSignUp();
    openLogin();
  }

  const validateEmail = (val) => {
    return emailRegex.test(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    const fn = formDataState.fullName.trim();

    if (!nameRegex.test(fn) || fn === '') {
      newErrors.fullName = 'Full Name is required';
    }

    if (!formDataState.phone.trim()) {
      setErrorPhone('Phone number is required');
    }

    if (!formDataState.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(formDataState.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!formDataState.password.trim()) {
      newErrors.password = 'Password is required';
    } else if (formDataState.password.length < 6) {
      newErrors.password = 'Minimum password width is 6 characters';
    }

    if (!formDataState.agreeToTerms) {
      newErrors.agreeToTerms = 'You must agree to Terms & Conditions';
    }

    if (Object.keys(newErrors).length === 0) {
      const url = registerUser('register');

      const formData = new FormData();
      formData.append('user[email]', formDataState.email);
      formData.append('user[password]', formDataState.password);
      formData.append('user[user_name]', formDataState.fullName);
      formData.append('user[phone_number]', formDataState.phone.replace(/[)( -]/g, ''));
      formData.append('user[country]', formDataState.country);
      formData.append('user[country_code]', formDataState.countryCode);

      const options = {
        method: 'POST',
        body: formData,
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 'success') {
            console.log('user registered');

            //
            // if (clickedGameUrl !== homepageGame) {
            //   navigate(`/games/${clickedGameUrl}`);
            // }

            // navigate(0);
          } else {
            if (result.message === 'Password is incorrect') {
              newErrors.password = result.message;
            } else {
              newErrors.email = result.message;
            }
            // setUAuth(false);

            setErrors(newErrors);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          closeSignUp();
          setIsThankYouPopupOpen(true);

          setLoggedIn(true);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    } else {
      setErrors(newErrors);
    }
  };

  const skipAndPlayButtonHandler = () => {
    if (window.location.href.includes('/game/')) {
      closeSignUp();
    } else {
      let game = localStorage.getItem("selectedGame");

      if (!game) {
        game = gamesList[0].url
      }

      closeSignUp(false);
      navigate(`/game/${game}`);
    }
  };

  if (loggedIn) {
    return <></>;
  }

  return (
    isSignUpPopupOpen && (
      <div className="fixed top-0 w-full z-40 overflow-y-scroll">
        <div className="flex items-center justify-center relative w-full h-[100vh]">
          <div
            onClick={closeSignUp}
            className="z-40 fixed w-full inset-0 flex items-center justify-center bg-black bg-opacity-60"
          ></div>
          <div className="w-full max-w-[680px] shadow-md absolute z-50 mx-4 px-[30px] md:px-[54px] py-[45px] pb-10 md:mx-0 flex flex-col rounded-lg  bg-textBlack from-popupOrangePrimary to-popupOrangeSecondary shadow-md">
            <div onClick={closeSignUp} className="absolute right-[10px] top-[10px] cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect
                  x="9.41211"
                  y="29.4121"
                  width="28.2843"
                  height="2.49567"
                  transform="rotate(-45 9.41211 29.4121)"
                  fill="#C4C4C4"
                />
                <rect
                  x="29.4121"
                  y="30.5879"
                  width="28.2843"
                  height="2.49567"
                  transform="rotate(-135 29.4121 30.5879)"
                  fill="#C4C4C4"
                />
              </svg>
            </div>
            <h1 className="text-[38px] font-bold text-center leading-[50px] mb-2">Welcome</h1>
            <p className="font-montserrat font-bold text-center text-[16px]"> Please fill in the form to be informed about new free games</p>
            <form
                onSubmit={handleSubmit}
                className="text-black flex flex-col items-center justify-center mt-[24px] mx-auto gap-[26px] w-full"
            >
              <input
                  placeholder="Full name"
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formDataState.fullName}
                  onChange={handleChange}
                  className="w-full rounded-[8px] font-montserrat px-6 py-4 text-black outline-0 lg:py-[18px]"
              />
              {errors.fullName && (
                  <p className="text-red mt-[-20px] mb-[-20px]">{errors.fullName}</p>
              )}
              <IntlTelInput
                  placeholder="Phone"
                  inputClassName="w-full rounded-[8px] font-montserrat px-6 py-4 text-black outline-0 lg:py-[18px]"
                  preferredCountries={[countryData.code]}
                  fieldId={'phone-number'}
                  onPhoneNumberChange={(isValid, a, b, number) => {
                    let name = b.name.split('(')[0];
                    name = name.trim();
                    let countryCode = b.iso2.toString();
                    setErrorPhone(false);
                    if (isValid) {
                      formDataState.phone = number;
                      formDataState.country = name;
                      formDataState.countryCode = countryCode;
                      setErrorPhone(null);
                    } else {
                      setErrorPhone('Invalid phone number');
                    }
                  }}
              />
              {errorPhone && <p className="text-red mt-[-20px] mb-[-20px] ">{errorPhone}</p>}
              <input
                  placeholder="Enter a valid email address"
                  type="email"
                  id="email"
                  name="email"
                  value={formDataState.email}
                  onChange={handleChange}
                  className="w-full rounded-[8px] font-montserrat px-6 py-4 text-black outline-0 lg:py-[18px]"
              />
              {errors.email && <p className="text-red mt-[-20px] mb-[-20px]">{errors.email}</p>}
              <input
                  placeholder="Enter Password"
                  id="password"
                  name="password"
                  type="password"
                  value={formDataState.password}
                  onChange={handleChange}
                  className="w-full rounded-[8px] font-montserrat px-6 py-4 text-black outline-0 lg:py-[18px]"
              />
              {errors.password && (
                  <p className="text-red mt-[-20px] mb-[-20px]">{errors.password}</p>
              )}

              <div>


              <div className="flex text-white">
                <Checkbox
                    type="checkbox"
                    name="agreeToTerms"
                    id="agreeToTerms"
                    checked={formDataState.agreeToTerms}
                    onChange={handleChange}
                />
                <label className="ml-6 font-bold text-[16px] md:text-xl">
                  I agree to
                  <a className="underline text-[16px] font-bold ml-2" href="/terms-and-conditions">
                    Terms & Conditions
                  </a>
                </label>
              </div>
              {errors.agreeToTerms && (
                  <p className="text-red mt-[10px] mb-[10px]">{errors.agreeToTerms}</p>
              )}


              <div className="flex items-center justify-start text-white mt-4">
                <Checkbox
                    type="checkbox"
                    name="promo"
                    id="promo"
                    checked={formDataState.promo}
                    onChange={handleChange}
                />
                <label className="ml-6 font-bold  md:text-xl">
                  I would like to receive exclusive promotions via Email & SMS
                </label>
              </div>
              </div>


              <div
                  className="flex flex-col items-center justify-center text-white w-full justify-center gap-[25px] lg:flex-row md:gap-[20px] md:mt-[20px] text-[16px]">
                <div className="w-full md:text-3xl">
                  <Button buttonClass="large" title="Sign Up" type="submit"/>
                </div>
                {registrationCookie !== 'force' && (
                    <>
                      <div className="w-full text-[16px] md:text-3xl flex text-center">
                        <Button
                            buttonClass="large"
                            title="Skip&Play"
                            type="button"
                            onClick={skipAndPlayButtonHandler}
                        />
                      </div>
                    </>
                )}
              </div>

              <div className="text-white font-bold">
                Do you already have an account? <button onClick={handleLogin}>Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

import React, { createContext, useEffect, useState } from 'react';
import checkUserAuth from '../utils/userData';
import { logout } from '../api/api';
import { useNavigate } from 'react-router';
import useSecretCookies from '../customHooks/useSecretCookies';
import {gamesList} from "../data/gamesList";

export const RootContext = createContext();


export const RootProvider = ({ children }) => {

  const [loggedIn, setLoggedIn] = useState(false);
  const [countryData, setCountryData] = useState({ code: 'US', country: 'USA' });
  const [isSignUpPopupOpen, setIsSignUpPopupOpen] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isThankYouPopupOpen, setIsThankYouPopupOpen] = useState(false);
  const { setSecretCookies } = useSecretCookies();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    checkUserAuth()
      .then((result) => {
        if (result && result.is_auth === true) {
          setLoggedIn(true);
          console.log(result);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://ipapi.co/json/?key=1W62DydpgUZwtxR5l6BW8UPckBu2NLTTJLzOiuqtkwHgz4tNXz`,
        );
        if (response.ok) {
          const data = await response.json();

          setSecretCookies('sc_geo', {
            address_country: data.country_name,
            address_city: data.city,
            address_state: data.region,
            address_zip: data.postal,
          });

          const country_data = {
            code: data.country_code,
            country: data.country_name,
          };
          setCountryData(country_data);
        } else {
          console.error('Response error:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []);

  const userLogout = async () => {
    const url = logout();
    await fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setLoggedIn(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const openSignUp = () => {
    setIsSignUpPopupOpen(true);
  };

  const closeSignUp = () => {
    setIsSignUpPopupOpen(false);
  };

  const openLogin = () => {
    setIsLoginPopupOpen(true);
  };

  const closeLogin = () => {
    setIsLoginPopupOpen(false);
  };

  const switchPopup = () => {
    setIsSignUpPopupOpen(true);
    setIsLoginPopupOpen(false);
  };


  const checkForLoggedIn = () => {
    let game = localStorage.getItem("selectedGame");
    console.log(game);

    if (!game) {
      game = gamesList[0].url;
    }

    if (loggedIn) {
      navigate(`/game/${game}`);
    } else {
      setIsSignUpPopupOpen(true);
    }
  };

  function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }
  const registrationCookie = getCookie('registration');

  return (
    <RootContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        countryData,
        isSignUpPopupOpen,
        openSignUp,
        closeSignUp,
        isLoginPopupOpen,
        toggleMenu,
        isMenuOpen,
        openLogin,
        closeLogin,
        switchPopup,
        checkForLoggedIn,
        registrationCookie,
        userLogout,
        isThankYouPopupOpen,
        setIsThankYouPopupOpen,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

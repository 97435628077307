import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { GoBack } from '../components';

export const Disclaimer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'WildfallSlots | Disclaimer';
  }, []);

  return (
      <section className="mt-10 px-4 mb-10 min-h-[70vh] lg:px-[330px]">

        <h1 className="text-3xl leading-[54px] lg:text-[40px] lg:text-center">Disclaimer</h1>
        <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">


          {t('disclaimer:content')}
          <p>{t('disclaimer:line1')}</p>
          <p>{t('disclaimer:line2')}</p>
          <p>{t('disclaimer:line3')}</p>
          <p>{t('disclaimer:line4')}</p>
          <p>{t('disclaimer:line5')}</p>
          <p>{t('disclaimer:line6')}</p>
        </div>
      </section>
  );
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'WildfallSlots | Privacy Policy';
  }, []);

  const renderLines = () => {
    const lines = [];
    const lng = localStorage.getItem('lng');

    if (lng === 'bing') {
      for (let i = 1; i <= 36; i++) {
        const lineKey = `privacy:line${i}`;
        if (t(lineKey)) {
          lines.push(<p key={`line-${i}`}>{t(lineKey)}</p>);
        }
      }
    } else {
      for (let i = 1; i <= 36; i++) {
        const lineKey = `privacy:line${i}`;
        if (t(lineKey)) {
          lines.push(<p key={`line-${i}`}>{t(lineKey)}</p>);
        }
      }
    }

    return lines;
  };

  return (
    <section className="mt-10 px-4 mb-10 min-h-[70vh] lg:px-[330px]">
      <h1 className="text-3xl leading-[54px] lg:text-center lg:text-[40px]">Privacy Policy</h1>
      <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">
        {renderLines()}
      </div>
    </section>
  );
};

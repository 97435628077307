import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

export const Rsg = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = 'WildfallSlots | Responsible Social Gaming Policy';
    }, []);

    return (<section className="flex flex-col items-center p-2">

            <div className="flex flex-col items-center mt-[70px] max-w-[1000px]">
                <h1 className="text-[28px] text-center leading-[54px] lg:text-[40px]">RESPONSIBLE SOCIAL GAMING POLICY OF WILDFALLSLOTS</h1>
            </div>

            <div className="flex flex-col gap-3 mt-[70px] max-w-[1000px]">

                <div className="text-start">
                    <h1 className="font-[22px] leading-[54px] lg:text-[30px]">{t('rsg:title1')}</h1>
                    <p>{t('rsg:content1')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title2')}</h1>
                    <p>{t('rsg:content2_1')}</p>
                    <p>{t('rsg:content2_2')}</p>
                    <p>{t('rsg:content2_3')}</p>
                    <p>{t('rsg:content2_4')}</p>
                    <p>{t('rsg:content2_5')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title3')}</h1>
                    <p>{t('rsg:content3_1')}</p>
                    <p>{t('rsg:content3_2')}</p>
                    <p>{t('rsg:content3_3')}</p>
                    <p>{t('rsg:content3_4')}</p>
                    <p>{t('rsg:content3_5')}</p>
                </div>


                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title4')}</h1>
                    <p>{t('rsg:content4_1')}</p>
                    <p>{t('rsg:content4_2')}</p>
                    <p>{t('rsg:content4_3')}</p>
                    <p>{t('rsg:content4_4')}</p>
                    <p>{t('rsg:content4_5')}</p>
                    <p>{t('rsg:content4_6')}</p>
                    <p>{t('rsg:content4_7')}</p>
                    <p>{t('rsg:content4_8')}</p>
                    <p>{t('rsg:content4_9')}</p>
                    <p>{t('rsg:content4_10')}</p>
                    <p>{t('rsg:content4_11')}</p>
                    <p>{t('rsg:content4_12')}</p>
                    <p>{t('rsg:content4_13')}</p>
                    <p>{t('rsg:content4_14')}</p>
                </div>


                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title5')}</h1>
                    <p>{t('rsg:content5_1')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title6')}</h1>
                    <p>{t('rsg:content6_1')}</p>
                    <p>{t('rsg:content6_2')}</p>
                    <p>{t('rsg:content6_3')}</p>
                    <p>{t('rsg:content6_4')}</p>
                    <p>{t('rsg:content6_5')}</p>
                    <p>{t('rsg:content6_6')}</p>
                    <p>{t('rsg:content6_7')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title7')}</h1>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title8')}</h1>
                    <p>{t('rsg:content8_1')}</p>
                    <p>{t('rsg:content8_2')}</p>
                    <p>{t('rsg:content8_3')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title9')}</h1>
                    <p>{t('rsg:content9_1')}</p>
                    <p>{t('rsg:content9_2')}</p>
                    <p>{t('rsg:content9_3')}</p>
                    <p>{t('rsg:content9_4')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title10')}</h1>
                    <p>{t('rsg:content10_1')}</p>
                    <p>{t('rsg:content10_2')}</p>
                    <p>{t('rsg:content10_3')}</p>
                    <p>{t('rsg:content10_4')}</p>
                    <p>{t('rsg:content10_5')}</p>
                    <p>{t('rsg:content10_6')}</p>
                    <p>{t('rsg:content10_7')}</p>
                    <p>{t('rsg:content10_8')}</p>
                    <p>{t('rsg:content10_9')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title11')}</h1>
                    <p>{t('rsg:content11_1')}</p>
                    <p>{t('rsg:content11_2')}</p>
                    <p>{t('rsg:content11_3')}</p>
                    <p>{t('rsg:content11_4')}</p>
                    <p>{t('rsg:content11_5')}</p>
                    <p>{t('rsg:content11_6')}</p>
                    <p>{t('rsg:content11_7')}</p>
                    <p>{t('rsg:content11_8')}</p>
                    <p>{t('rsg:content11_9')}</p>
                    <p>{t('rsg:content11_10')}</p>
                    <p>{t('rsg:content11_11')}</p>
                    <p>{t('rsg:content11_12')}</p>
                    <p>{t('rsg:content11_13')}</p>
                    <p>{t('rsg:content11_14')}</p>
                    <p>{t('rsg:content11_15')}</p>
                    <p>{t('rsg:content11_16')}</p>
                    <p>{t('rsg:content11_17')}</p>
                    <p>{t('rsg:content11_18')}</p>
                    <p>{t('rsg:content11_19')}</p>
                    <p>{t('rsg:content11_20')}</p>
                    <p>{t('rsg:content11_21')}</p>
                    <p>{t('rsg:content11_22')}</p>
                    <p>{t('rsg:content11_23')}</p>
                    <p>{t('rsg:content11_24')}</p>
                    <p>{t('rsg:content11_25')}</p>
                    <p>{t('rsg:content11_26')}</p>
                    <p>{t('rsg:content11_27')}</p>
                    <p>{t('rsg:content11_28')}</p>
                    <p>{t('rsg:content11_29')}</p>
                    <p>{t('rsg:content11_30')}</p>
                    <p>{t('rsg:content11_31')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title12')}</h1>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title13')}</h1>
                    <p>{t('rsg:content13_1')}</p>
                    <p>{t('rsg:content13_2')}</p>
                    <p>{t('rsg:content13_3')}</p>
                    <p>{t('rsg:content13_4')}</p>
                    <p>{t('rsg:content13_5')}</p>
                    <p>{t('rsg:content13_6')}</p>
                    <p>{t('rsg:content13_7')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title14')}</h1>
                    <p>{t('rsg:content14_1')}</p>
                    <p>{t('rsg:content14_2')}</p>
                    <p>{t('rsg:content14_3')}</p>
                    <p>{t('rsg:content14_4')}</p>
                    <p>{t('rsg:content14_5')}</p>
                    <p>{t('rsg:content14_6')}</p>
                    <p>{t('rsg:content14_7')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title15')}</h1>
                    <p>{t('rsg:content15_1')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title16')}</h1>
                    <p>{t('rsg:content16_1')}</p>
                    <p>{t('rsg:content16_2')}</p>
                    <p>{t('rsg:content16_3')}</p>
                    <p>{t('rsg:content16_4')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title17')}</h1>
                    <p>{t('rsg:content17_1')}</p>
                    <p>{t('rsg:content17_2')}</p>
                    <p>{t('rsg:content17_3')}</p>
                    <p>{t('rsg:content17_4')}</p>
                    <p>{t('rsg:content17_5')}</p>
                    <p>{t('rsg:content17_6')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title18')}</h1>
                    <p>{t('rsg:content18_1')}</p>
                    <p>{t('rsg:content18_2')}</p>
                    <p>{t('rsg:content18_3')}</p>
                    <p>{t('rsg:content18_4')}</p>
                    <p>{t('rsg:content18_5')}</p>
                    <p>{t('rsg:content18_6')}</p>
                    <p>{t('rsg:content18_7')}</p>
                    <p>{t('rsg:content18_8')}</p>
                    <p>{t('rsg:content18_9')}</p>
                    <p>{t('rsg:content18_10')}</p>
                    <p>{t('rsg:content18_11')}</p>
                    <p>{t('rsg:content18_12')}</p>
                    <p>{t('rsg:content18_13')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title19')}</h1>
                    <p>{t('rsg:content19_1')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title20')}</h1>
                    <p>{t('rsg:content20_1')}</p>
                    <p>{t('rsg:content20_2')}</p>
                    <p>{t('rsg:content20_3')}</p>
                    <p>{t('rsg:content20_4')}</p>
                    <p>{t('rsg:content20_5')}</p>
                    <p>{t('rsg:content20_6')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title21')}</h1>
                    <p>{t('rsg:content21_1')}</p>
                    <p>{t('rsg:content21_2')}</p>
                    <p>{t('rsg:content21_3')}</p>
                    <p>{t('rsg:content21_4')}</p>
                    <p>{t('rsg:content21_5')}</p>
                    <p>{t('rsg:content21_6')}</p>
                    <p>{t('rsg:content21_7')}</p>
                    <p>{t('rsg:content21_8')}</p>
                    <p>{t('rsg:content21_9')}</p>
                    <p>{t('rsg:content21_10')}</p>
                    <p>{t('rsg:content21_11')}</p>
                    <p>{t('rsg:content21_12')}</p>
                    <p>{t('rsg:content21_13')}</p>
                    <p>{t('rsg:content21_14')}</p>
                    <p>{t('rsg:content21_15')}</p>
                    <p>{t('rsg:content21_16')}</p>
                    <p>{t('rsg:content21_17')}</p>
                    <p>{t('rsg:content21_18')}</p>
                    <p>{t('rsg:content21_19')}</p>
                    <p>{t('rsg:content21_20')}</p>
                    <p>{t('rsg:content21_21')}</p>
                    <p>{t('rsg:content21_22')}</p>
                    <p>{t('rsg:content21_23')}</p>
                    <p>{t('rsg:content21_24')}</p>
                    <p>{t('rsg:content21_25')}</p>
                    <p>{t('rsg:content21_26')}</p>
                    <p>{t('rsg:content21_27')}</p>
                    <p>{t('rsg:content21_28')}</p>
                    <p>{t('rsg:content21_29')}</p>
                    <p>{t('rsg:content21_30')}</p>
                    <p>{t('rsg:content21_31')}</p>
                    <p>{t('rsg:content21_32')}</p>
                    <p>{t('rsg:content21_33')}</p>
                    <p>{t('rsg:content21_34')}</p>
                    <p>{t('rsg:content21_35')}</p>
                    <p>{t('rsg:content21_36')}</p>
                    <p>{t('rsg:content21_37')}</p>
                    <p>{t('rsg:content21_38')}</p>
                    <p>{t('rsg:content21_39')}</p>
                    <p>{t('rsg:content21_40')}</p>
                    <p>{t('rsg:content21_41')}</p>
                    <p>{t('rsg:content21_42')}</p>
                    <p>{t('rsg:content21_43')}</p>
                    <p>{t('rsg:content21_44')}</p>
                    <p>{t('rsg:content21_45')}</p>
                    <p>{t('rsg:content21_46')}</p>
                    <p>{t('rsg:content21_47')}</p>
                    <p>{t('rsg:content21_48')}</p>
                    <p>{t('rsg:content21_49')}</p>
                    <p>{t('rsg:content21_50')}</p>
                    <p>{t('rsg:content21_51')}</p>
                </div>

                <div className="text-start">
                    <h1 className="text-3xl leading-[54px] lg:text-[30px]">{t('rsg:title_22')}</h1>
                    <p>{t('rsg:content22_1')}</p>
                    <p>{t('rsg:content22_2')}</p>
                    <p>{t('rsg:content22_3')}</p>
                    <p>{t('rsg:content22_4')}</p>
                    <p>{t('rsg:content22_5')}</p>
                    <p>{t('rsg:content22_6')}</p>
                    <p>{t('rsg:content22_7')}</p>
                    <p>{t('rsg:content22_8')}</p>
                    <p>{t('rsg:content22_9')}</p>
                    <p>{t('rsg:content22_10')}</p>
                    <p>{t('rsg:content22_11')}</p>
                    <p>{t('rsg:content22_12')}</p>
                    <p>{t('rsg:content22_13')}</p>
                </div>


            </div>
        </section>
    );
};

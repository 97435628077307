import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';
import { useTranslation } from 'react-i18next';
import { Games } from './Games';
import { Button } from '../components';

import landingBg from '../assets/images/bg-img1.png';
import gameImage from "../assets/images/game-1.png";

export const LandingPage = () => {
  const { checkForLoggedIn, isMenuOpen } = useContext(RootContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'WildfallSlots | Home';

    document.body.style.backgroundColor = '#003B87';
    document.body.style.backgroundImage = `url('${landingBg}')`;
    document.body.style.backgroundPosition = 'top 55%';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.backgroundImage = '';
      document.body.style.backgroundPosition = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
    };
  });

  useEffect(() => {
    document.body.style.position = isMenuOpen ? 'fixed' : 'relative';
  }, [isMenuOpen]);

  return (
      <section className="w-full flex flex-col items-center mt-[20px] lg:mt-[150px]">

        <div className='flex flex-col items-center'>
          <h1 className="text-[28px] lg:text-[42px] font-bold leading-[54px] text-center">
            {t('landing:heading1')}
          </h1>

          <p className='text-[24px] px-2 mt-[40px] text-center lg:px-[200px]'>
            {t('landing:subheading1')}
          </p>
        </div>

        <div className='flex flex-col mx-4 items-center justify-center mt-[68px] gap-[60px] lg:flex-row'>

          <div className='mt-50px p-6 bg-[#242B46]  max-w-[544px] rounded-[24px]'>
            <h1 className='text-[42px] text-[#FCF45F]  mb-[20px] font-bold'>{t('landing:heading2')}</h1>
            <p className='text-[20px]'>{t('landing:subheading2')}</p>
          </div>

          <div className='mt-50px p-6 bg-[#242B46]  max-w-[544px] rounded-[24px]'>
            <h1 className='text-[42px] text-[#FCF45F]  mb-[20px] font-bold'>{t('landing:heading3')}</h1>
            <p className='text-[20px]'>{t('landing:subheading3')}</p>
          </div>

          <div className='mt-50px p-6 bg-[#242B46]  max-w-[544px] rounded-[24px]'>
            <h1 className='text-[42px] text-[#FCF45F]  mb-[20px] font-bold'>{t('landing:heading4')}</h1>
            <p className='text-[20px]'>{t('landing:subheading4')}</p>
          </div>

        </div>

        <Games/>

        <section className="bg-textBlack mx-4 my-8 lg:flex  lg:mb-[200px] rounded-[18px]">

          <div className="">
            <img className="w-full h-full" src={gameImage} alt={gameImage}/>
          </div>

          <div className='flex flex-col p-3 text-center items-center justify-center lg:text-start lg:items-start lg:mx-[100px]'>
            <h1 className="text-[36px]  font-bold leading-8 mt-10 lg:text-[36px] lg:max-w-[590px] lg:leading-[54px] ">
              {t('landing:heading6')}
            </h1>

            <p className="text-[16px] leading-[24px] mt-[40px] lg:text-[18px] lg:leading-[28px] lg:max-w-[590px] lg:order-2 lg:mt-[23px]">
              {t('landing:subheading6')}
            </p>

            <div className='max-w-[170px] mt-[26px] mb-[51px] lg:order-3'>
              <Button buttonClass="mini" title="Play now" type="button" onClick={checkForLoggedIn}/>
            </div>
          </div>
        </section>

      </section>
  );
};

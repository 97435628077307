import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';

import { useParams } from 'react-router';

import { GoBack } from '../components';

import { gamesList } from '../data/gamesList.js';
import {useNavigate} from "react-router";
import GoBackButton from '../assets/images/backBtn.png';

export const Game = ({ match }) => {

  const navigate = useNavigate();
  const { url } = useParams();

  const { loggedIn, registrationCookie, openSignUp } = useContext(RootContext);

  useEffect(() => {
    document.title = 'WildfallSlots | Play';
    console.log(url);
    if (!loggedIn) {
      setTimeout(() => {
        openSignUp();
      }, 30000);
    }
  }, []);

  let selectedGame = gamesList.find((game) => game.url === url)

  if (!loggedIn && registrationCookie === 'force') {
    window.location.href = '/';
  }

  if (!selectedGame) {
      selectedGame = gamesList[0]
  }

  return (
    <section className="min-h-[80vh] px-4">
      <div className="w-full h-[680px] my-10">
        <div className="max-w-[1122px] h-full m-auto">
          <button onClick={() => navigate(-1)}>
            <img src={GoBackButton} alt="backButton"/>
          </button>
          <iframe className="w-full h-full mt-8 mb-8" title="game" src={selectedGame.link}></iframe>
        </div>
      </div>
    </section>
  );
};

import React from 'react';

import { Route, Routes } from 'react-router';

import { Layout, LayuotWithoutHeaderAndFooter } from './components/index.js';

import {
    About,
    Contact,
    CookiePolicy,
    Disclaimer,
    Game,
    Home,
    NotFoundPage,
    OptOut,
    TermsAndConditions,
    UsersPage,
    Games,
    LandingPage,
    PrivacyPolicy,
    Rsg,
    Cookies,
    Terms,
} from './pages/index.js';

import './index.css';

export const App = () => {
    return (
        <>
            <div className="z-[40] sticky top-0 flex p-2 items-center justify-center h-[40px] bg-brownBg lg:h-[50px]">
        <span className="font-montserrat text-sm font-normal">
          18+ All Games Are Free With No Option To Win Real Money Or Prizes!
        </span>
            </div>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/opt-out" element={<OptOut />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/games" element={<Games />} />
                    <Route path="/game/:url" element={<Game />} />
                    <Route path="/lp" element={<LandingPage />} />
                    <Route path="/rsg" element={<Rsg />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route element={<LayuotWithoutHeaderAndFooter />}>
                    <Route path="/userspage" element={<UsersPage />} />
                    <Route path="/terms" element={<Terms />} />
                </Route>
            </Routes>
        </>
    );
};

import React from 'react';

import Age from "../assets/images/logos/18.webp"
import GameStop from "../assets/images/logos/gamstop.webp"
import GameCare from "../assets/images/logos/GameCare.webp"
import BeGambleAware from "../assets/images/logos/BeGambleAware.webp"
import Gamblingtherapy from "../assets/images/logos/gamblingtherapy.webp"

import { useTranslation } from 'react-i18next';

import {ReactComponent as Logo} from '../assets/images/logo.svg';
export const Footer = () => {
  const { t } = useTranslation();

  return (
      <footer
          className="lg:absolute bg-brown-secondary pb-5 w-full mt-[100px] flex flex-col items-center px-6 pt-[36px] lg:pb-[42px]">
          <a href="/" className="lg:m-auto">
              <Logo/>
          </a>

          <div className="mt-5 mb-5 flex flex-col gap-[20px] lg:flex-row items-center lg:gap-[150px]">
              <img src={Age} alt="18"/>

              <a href="https://www.gamstop.co.uk/">
                  <img src={GameStop} height="30" width="166" alt="GameStop"/>
              </a>

              <a href="https://www.gamcare.org.uk/">
                  <img src={GameCare} height="30" width="103" alt="GameCare"/>
              </a>


              <a href="https://www.gamblingtherapy.org/">
                  <img src={Gamblingtherapy} height="30" width="100" alt="Gamblingtherapy"/>
              </a>

              <a href="https://www.begambleaware.org/">
                  <img src={BeGambleAware} height="33" width="277" alt="BeGambleAware"/>
              </a>

          </div>


          <div className="mt-[12px] flex flex-col items-center text-center m-auto md:max-w-[1016px] lg:text-center">
              <h3 className="text-[24px] font-bold lg:text-xl">{t('footer:heading-1')}</h3>
              <p className="mt-[10px] text-[18px] leading-[28px]  lg:text-base">
                  {t('footer:subheading-1')}
              </p>
          </div>

          <div
              className="font-montserrat text-base w-full flex flex-col items-center justify-center gap-[24px] mt-[34px] lg:mt-[40px] lg:text-xl lg:flex-row lg:items-center lg:justify-center lg:gap-8">
              <a href="/contact" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Contact
              </a>

              <a href="/cookie-policy" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Cookie policy
              </a>

              <a href="/disclaimer" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Disclaimer
              </a>

              <a href="/terms-and-conditions"
                 className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Terms & Conditions
              </a>

              <a href="/privacy-policy"
                 className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Privacy Policy
              </a>

              <a href="/rsg"
                 className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Responsible Social Gaming
              </a>
          </div>
      </footer>
  );
};

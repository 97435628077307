import React, { useEffect } from 'react';

import {Button} from "../components";
import notFoundImg from "../assets/images/notFound.png"

export const NotFoundPage = () => {
  useEffect(() => {
    document.title = 'WildfallSlots | Not found page 404';
  }, []);

  return (
      <section className="bg-[url('./assets/images/bg-new-1.png')] bg-auto bg-center bg-no-repeat h-full">
          <div className="flex flex-col items-center text-center justify-center px-10 pt-10 pb-32">

              <img src={notFoundImg} alt="notFoundImage"/>

              <h1 className="text-[36px] mt-4 leading-[54px] mb-6 font-bold lg:text-[42px]">Something Went Wrong</h1>

              <p className='text-[18px] font-bold leading-auto mb-6'>
                  Error 404 Page Not Found
              </p>

              <Button
                  buttonClass="mini"
                  title="Go Home"
                  anchorLink
                  anchorHref={`/`}
              />





          </div>


      </section>
  );
};

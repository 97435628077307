import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';

import { useTranslation } from 'react-i18next';

import { Button } from '../components/index.js';

import aboutImage from '../assets/images/bg-about-img.png';

export const About = () => {
  const { checkForLoggedIn } = useContext(RootContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'WildfallSlots | About';
  }, []);

  return (
      <section className="bg-[url('./assets/images/bg-about-img.png')] pb-[200px] bg-contain bg-no-repeat bg-bottom lg:bg-auto lg:bg-right-top lg:pb-0 lg:flex lg:items-start lg:justify-start">
        <div
            className="flex flex-col items-center justify-center text-[36px] text-center px-[15px] lg:ml-[120px] md:max-w-[600px] lg:gap-6 lg:flex-row lg:items-start lg:text-start lg:justify-center lg:max-w-[820px] lg:px-6 lg:pb-16 lg:mt-[50px]">

          <div className="mt-[40px] pb-[20px] lg:mt-0 lg:pb-0">
            <h1 className="text-[36px] mb-[24px] font-bold leading-[32px] xl:text-[42px] xl:leading-[54px]">
              {t('about:heading-1')}
            </h1>
            <p className="text-[18px] leading-[28px] mt-1 lg:text-[20px]">{t('about:subheading-1')}</p>

            <h1 className="text-[22px] mb-[12px] mt-[24px] font-bold leading-[32px] xl:text-[24px] ">
              {t('about:heading-2')}
            </h1>
            <p className="text-[18px] leading-[28px] mt-1 lg:text-[20px]">{t('about:subheading-2')}</p>

            <h1 className="text-[22px] mb-[12px] mt-[12px]  font-bold leading-[32px] xl:text-[24px]">
              {t('about:heading-3')}
            </h1>
            <p className="text-[18px] leading-[28px] mt-1 lg:text-[20px]">{t('about:subheading-3')}</p>

            <h1 className="text-[22px] mb-[12px] mt-[12px]  font-bold leading-[32px] xl:text-[24px]">
              {t('about:heading-4')}
            </h1>
            <p className="text-[18px] leading-[28px] mt-1 lg:text-[20px]">{t('about:subheading-4')}</p>

            <div className="max-w-[220px] mx-auto lg:mx-0 lg:max-w-[307px] mt-10 mb-10 p-[6px]">
              <Button title="Play Now" buttonClass="biggest" onClick={checkForLoggedIn}/>
            </div>
          </div>
        </div>

        {/*<div className=''>*/}
        {/*  <img*/}
        {/*      src={aboutImage}*/}
        {/*      alt={aboutImage}*/}
        {/*      className="w-full"*/}
        {/*  />*/}
        {/*</div>*/}

      </section>
  );
};

import wildslots from '../assets/images/games/wild-slots.png';
import bootyBay from '../assets/images/games/booty-bay.png';
import eyeOfAtum from '../assets/images/games/eye-of-atum.png';
import jadeButterfly from '../assets/images/games/jade-butterfly.png';
import elvesKingdom from '../assets/images/games/elves-kingdom.png';
import bananaRock from '../assets/images/games/bananaRock.png'
import cashVandal from '../assets/images/games/cashVandal.png'
import catsandcash from '../assets/images/games/catsandcash.png'
import pimped from '../assets/images/games/pimped.png'
import chickenEscape  from '../assets/images/games/chickenEscape.png'

import game5 from "../assets/images/games/game-5.webp"
import game6 from "../assets/images/games/game-6.webp"
import game9 from "../assets/images/games/game-9.webp"
import game11 from "../assets/images/games/game-11.webp"
import game12 from "../assets/images/games/game-12.webp"
import game16 from "../assets/images/games/game-16.webp"
import game19 from "../assets/images/games/game-19.webp"
import game21 from "../assets/images/games/game-21.webp"
import game25 from "../assets/images/games/game-25.webp"
import game35 from "../assets/images/games/game-35.webp"

export const gamesList = [

  {
    url: 'wild-slots',
    title: 'Wild Slots',
    img: wildslots,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&lang=en_GB&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&demo=2&rccurrentsessiontime=0&rcintervaltime=0&autoplaylimits=0&autoplayreset=0&callback=flashCallback&resourcelevel=0&gid=wildfalls&gameId=350',
  },
  {
    url: 'booty-bay',
    title: 'Booty Bay',
    img: bootyBay,
    link: 'https://ingamemt.solidgaming.net/api/launch/VERAJOHN/PSH_BOOTY_BAY?token=&exiturl=https://www.verajohn.se/game/booty-bay/return&platform=DESKTOP&language=en&currency=EUR&rcintervalseconds=0&rcsessionelapsed=0&rcsessionbalance=0&historyurl=https://www.verajohn.se/myaccount/transactionsbootybay',
  },
  {
    url: 'jade-butterfly',
    title: 'Jade Butterfly',
    img: jadeButterfly,
    link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=USD&gameSymbol=vs1024butterfly&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobbyURL=https%3A%2F%2Fwww.pragmaticplay.com ',
  },
  {
    url: 'eye-of-atum',
    title: 'Eeye of atum',
    img: eyeOfAtum,
    link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=eyeofatum&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
  },
  {
    url: 'cats-and-cash',
    title: 'Cats and Cash',
    img: catsandcash,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=catsandcash&gameId=193&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
  },
  {
    url: 'chicken-escape',
    title: 'The Great Chicken Escape',
    img: chickenEscape,
    link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=USD&gameSymbol=vs20chicken&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobbyURL=https%3A%2F%2Fwww.pragmaticplay.com',
  },
  {
    url: 'elves-kingdom',
    title: 'Elves Kingdom',
    img: elvesKingdom,
    link: 'https://cors.mannagaming.com/game/ELVES_KINGDOM/?lan=en-US',
  },
  {
    url: 'banana-rock',
    title: 'Banana Rock',
    img: bananaRock,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bananarock&gameId=347&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
  },
  {
    url: 'cash-vandal',
    title: 'Cash Vandal',
    img: cashVandal,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=cashvandal&gameId=370&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
  },
  {
    url: 'pimped',
    title: 'Pimped',
    img: pimped,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=pimped&gameId=291&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country= ',
  },

  {
    url: 'basketball',
    title: 'Basketball',
    img: game5,
    link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/basketball',
  },

  {
    url: 'hokey-leage-wild-match',
    title: 'Hokey Leage wild match',
    img: game6,
    link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs9hockey&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F&lang=EN&cur=USD',
  },

  {
    url: 'blackjack-double-exposure',
    title: 'Blackjack Double Exposure',
    img: game9,
    link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=doubleexposureblackjackmh&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
  },

  {
    url: 'black-jack-lucky-7',
    title: 'Black Jack Lucky 7',
    img: game11,
    link: 'https://demo.evoplay.games/demo/table/html5/evoplay/blackjack',
  },

  {
    url: 'european-roulette',
    title: 'European Roulette',
    img: game12,
    link: 'https://demo.evoplay.games/demo/table/html5/evoplay/europeanroulette',
  },

  {
    url: 'bonanza-wheel',
    title: 'Bonanza Wheel',
    img: game16,
    link: 'https://demo.evoplay.games/demo/instant/html5/evoplay/bonanzawheel',
  },

  {
    url: 'texas-holdem-3d',
    title: 'Texas Holdem 3d',
    img: game19,
    link: 'https://demo.evoplay.games/demo/table/html5/evoplay/texasholdempoker3d',
  },

  {
    url: 'oasis-poker',
    title: 'Oasis Poker',
    img: game21,
    link: 'https://demo.evoplay.games/demo/table/html5/evoplay/oasispokerclassic',
  },

  {
    url: 'shadow-of-luxor',
    title: 'Shadow of Luxor',
    img: game25,
    link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/shadowofluxorjackpot',
  },

  {
    url: 'jackpot-fortunes',
    title: 'Jackpot Fortunes',
    img: game35,
    link: 'https://rgstorgs.stage.pariplaygames.com/pariplay?mode=fun&currency=USD&lang=EN&gameCode=PP_HTML5_JackpotFortunes96&homeUrl=&debugstate=&operatorId=PP',
  },


];

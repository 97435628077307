import React, {useContext, useEffect, useState} from 'react';
import { RootContext } from '../context/RootContext';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/index.js';

import gameImage from '../assets/images/game-1.png';
import homeImg1 from '../assets/images/home-img-1.svg'
import homeImg2 from '../assets/images/home-img-2.png'
import landingBg from "../assets/images/bg-img1.png";

export const Home = () => {
  const { checkForLoggedIn, isMenuOpen } = useContext(RootContext);
  const { t } = useTranslation();

    useEffect(() => {
        localStorage.removeItem('selectedGame')
    },[])

    useEffect(() => {
        document.title = 'WildfallSlots | Home';

        document.body.style.backgroundColor = '#003B87';
        document.body.style.backgroundImage = `url('${landingBg}')`;
        document.body.style.backgroundPosition = 'top 400px';
        document.body.style.backgroundRepeat = 'no-repeat';

        return () => {
            document.body.style.backgroundColor = '';
            document.body.style.backgroundImage = '';
            document.body.style.backgroundPosition = '';
            document.body.style.backgroundSize = '';
            document.body.style.backgroundRepeat = '';
        };
    });

    useEffect(() => {
        document.body.style.position = isMenuOpen ? 'fixed' : 'relative';
    }, [isMenuOpen]);

  return (
      <div  className='flex flex-col items-center w-full lg:pt-16'>
        <section
            className="w-full  pt-[30px] px-6 overflow-hidden  lg:pt-28 lg:pb-[150px] z-10 max-w-[1220px]">

          <div className='flex items-center justify-center mb-[24px]'>
            <img className="w-full max-w-[264px] lg:max-w-[500px]" src={homeImg1} alt="homeImg1"/>
          </div>

          <div className="flex flex-col items-center justify-center text-center lg:w-full">
            <h1 className="order-1 text-[36px] leading-[48px] font-bold leading-8 lg:text-[36px] lg:leading-[54px] lg:mt-[25px] lg:mb-[0px] z-10">
                {t('home:heading-1')}
            </h1>
            <p className="order-3 text-[16px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:order-2 lg:mt-[23px] ">
                {t('home:subheading-1')}
            </p>
            <div className="mt-[24px] order-3  lg:order-3">
              <Button title="Play Now" buttonClass="biggest" onClick={checkForLoggedIn}/>
            </div>
          </div>

          <div className='flex flex-col lg:flex-row lg:mt-[270px]'>

            <div className='flex items-center justify-center mt-[92px] mb-[24px] lg:mt-0 lg:mb-0 lg:min-w-[300px]'>
              <img className="w-full" src={homeImg2} alt={homeImg2}/>
            </div>

            <div className='px-6 flex flex-col items-center justify-center text-center lg:max-w-[744px] lg:items-start lg:text-start lg:ml-[120px]'>
              <h1 className="order-1 text-[36px] leading-[48px] font-bold leading-8 lg:text-[42px] lg:leading-[54px] lg:mt-0 lg:mb-[0px]">
                {t('home:heading-2')}
              </h1>
              <p className="order-3 text-[16px] mb-[50px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:order-2">
                  {t('home:subheading-2')}
              </p>
            </div>

          </div>

        </section>


        <section className="bg-[url('./assets/images/home-bg-2.png')] w-full bg-cover bg-no-repeat lg:py-40">
          <div className='flex flex-col text-center px-3 mt-[230px] lg:pl-[340px] justify-center lg:text-start lg:mt-0 mb-[60px] lg:px-6'>
            <h1 className="order-1 text-[36px] leading-[48px] font-bold leading-8 lg:max-w-[546px] lg:text-[36px] lg:leading-[54px] lg:mt-20">
                {t('home:heading-3')}
            </h1>
            <p className="order-3 text-[16px] leading-[24px] mt-[24px] lg:max-w-[546px]  lg:text-[16px] lg:leading-[28px] lg:order-2 lg:mt-[23px]">
                {t('home:subheading-3')}
            </p>
          </div>
        </section>


        <section className="bg-textBlack mx-4 my-8 lg:flex lg:my-[200px] rounded-[18px]">

            <div className="">
              <img className="w-full h-full"  src={gameImage} alt={gameImage}/>
            </div>

            <div className='flex flex-col px-2 text-center items-center justify-center lg:items-start lg:text-start lg:mx-[100px]'>
                <h1 className="text-[36px]  font-bold leading-8 mt-10 lg:text-[36px] lg:max-w-[590px] lg:leading-[54px] ">
                    {t('home:heading-4')}
                </h1>

                <p className="text-[16px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:max-w-[590px] lg:order-2 lg:mt-[23px]">
                    {t('home:subheading-4')}
                </p>

                <div className='max-w-[170px] mt-[26px] mb-[51px] lg:order-3'>
                  <Button buttonClass="mini" title="Play now" type="button" onClick={checkForLoggedIn} />
                </div>
              </div>
        </section>

      </div>
  );
};

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router";
import GoBackButton from '../assets/images/backBtn.png';

export const Terms = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'WildfallSlots | Terms and conditions';
  }, []);

  return (
      <section className="mt-10 px-4 pb-10 mb-10 min-h-[70vh] lg:px-[230px]">
        <h1 className="text-3xl leading-[54px] lg:text-[40px] lg:text-center">Terms and Conditions</h1>
        <button onClick={() => navigate(-1)}>
          <img src={GoBackButton} alt="backButton"/>
        </button>
        <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">
          {t('terms:content')}
          <p>{t('terms:line1')}</p>
          <p>{t('terms:line2')}</p>
          <p>{t('terms:line3')}</p>
          <p>{t('terms:line4')}</p>
          <p>{t('terms:line5')}</p>
          <p>{t('terms:line6')}</p>
          <p>{t('terms:line7')}</p>
          <p>{t('terms:line8')}</p>
          <p>{t('terms:line9')}</p>
          <p>{t('terms:line10')}</p>
          <p>{t('terms:line11')}</p>
          <p>{t('terms:line12')}</p>
          <p>{t('terms:line13')}</p>
          <p>{t('terms:line14')}</p>
          <p>{t('terms:line15')}</p>
          <p>{t('terms:line16')}</p>
          <p>{t('terms:line17')}</p>
          <p>{t('terms:line18')}</p>
          <p>{t('terms:line19')}</p>
          <p>{t('terms:line20')}</p>
          <p>{t('terms:line21')}</p>
          <p>{t('terms:line22')}</p>
          <p>{t('terms:line23')}</p>
          <p>{t('terms:line24')}</p>
          <p>{t('terms:line25')}</p>
          <p>{t('terms:line26')}</p>
          <p>{t('terms:line27')}</p>
          <p>{t('terms:line28')}</p>
          <p>{t('terms:line29')}</p>
          <p>{t('terms:line30')}</p>
          <p>{t('terms:line31')}</p>
        </div>
      </section>
  );
};
